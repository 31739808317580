import { css } from '@emotion/react';
export const baseFontSize = '16px';
export const containerWidth = '1200px';
export const containerSpacing = '20px';
export const headerHeight = '95px';
export const mobileHeaderHeight = '62px';

export const spacing = {
  0: '0px',
  3: '0.18rem',
  5: '0.313rem',
  10: '0.625rem',
  15: '0.94rem',
  20: '1.25rem',
  25: '1.563rem',
  30: '1.875rem',
  40: '2.5rem',
  50: '3.125rem',
  60: '3.75rem',
  70: '4.375rem',
  80: '5rem',
  90: '5.625rem',
  100: '6.25rem',
  120: '7.5rem',
  140: '8.5rem',
  200: '12.5rem',
} as const;

export const colorPalette = {
  main: '#292566',
  lightGreen: '#CCD9A9',
  lightGreen70: '#a5be93',
  lightBlue: '#D8E7EB',
  lightSand: '#DBD2B6',
  blue: '#D8E7EB',
  white_300: '#FFFFFFF',
  white_200: '#FDFBF9',
  white: '#F6F8F5',
  red: '#cc0000',
  orange: '#FFA500',
  grey: 'rgba(0, 0, 0, 0.25)',
  backdrop: 'rgba(0, 0, 0, 0.7)',
  black: '#000',
} as const;

export const fontSize = {
  14: '0.875rem',
  18: '1.125rem',
  24: '1.5rem',
  28: '1.75rem',
  30: '1.875rem',
  36: '2.25rem',
  40: '2.5rem',
  48: '3rem',
  56: '3.5rem',
  58: '3.625rem',
  60: '3.75rem',
  64: '4rem',
  72: '4.5rem',
  96: '6rem',
  300: '18.75rem',
} as const;
export const lineHeight = {
  1: '1',
  40: '2.5rem',
  48: '3rem',
  72: '4.5rem',
  80: '5rem',
};
export const zIndex = {
  positive: 1,
  negative: -1,
  header: 2,
  modalBackdrop: 3,
  modal: 4,
} as const;

export const fontFamily = {
  freightText: 'FreightText Pro',
  elido: 'Elido',
  elidoBook: 'Elido Book',
} as const;

export const fontWeight = {
  normal: 300,
  normal_400: 400,
  bold: 700,
} as const;

export const borderRadius = {
  30: '30px',
  circle: '50%',
} as const;

export const MobileBreakpoint = 768;
export const TabletBreakpoint = 992;
export const DesktopBreakpoint = 1280;
export const BigDesktopBreakpoint = 1400;

export const Breakpoint = {
  mobile: `@media(max-width: ${MobileBreakpoint}px)`,
  smallTablet: `@media(max-width: ${TabletBreakpoint - 1}px)`,
  tablet: `@media(max-width: ${DesktopBreakpoint - 1}px)`,
  desktop: `@media(min-width: ${DesktopBreakpoint}px)`,
  overBigDesktop: `@media(min-width: ${BigDesktopBreakpoint}px)`,
};

export const commonStyles = {
  h1: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['58']};
    color: ${colorPalette.main};
    margin: 0;
  `,
  h2: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['48']};
    color: ${colorPalette.main};
    margin: 0;
  `,
  h3: css`
    font-family: ${fontFamily.freightText};
    font-size: ${fontSize['36']};
    color: ${colorPalette.main};
    margin: 0;
  `,

  basicText: css`
    font-family: ${fontFamily.elidoBook};
    font-size: ${fontSize['30']};
    color: ${colorPalette.main};
    line-height: ${lineHeight['40']};
  `,
  container: css`
    max-width: ${containerWidth};
    margin: 0 auto;

    ${Breakpoint.smallTablet} {
      margin: 0;
    }
  `,
};
