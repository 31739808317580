import { Global, css } from '@emotion/react';
import { baseFontSize, Breakpoint, colorPalette } from '../../config/styles';
import Header from '../Header';

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }

          html,
          body {
            -webkit-text-size-adjust: 100%;
            font-size: ${baseFontSize};
            font-optical-sizing: auto;
            background: ${colorPalette.lightBlue};

            ${Breakpoint.tablet} {
              font-size: 14px;
            }
            ${Breakpoint.mobile} {
              font-size: 10px;
            }
          }

          body {
            margin: 0px;
            font-variant: lining-nums;
            line-height: 1;
          }
        `}
      />
      <Header />
      <div style={{ marginTop: '20px' }}>{children}</div>
    </>
  );
};

export default Layout;
