import { css } from '@emotion/react';
import {
  Breakpoint,
  colorPalette,
  fontFamily,
  fontSize,
  fontWeight,
  headerHeight,
  spacing,
  zIndex,
} from '../../config/styles';

import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Container from '../Container';

const styles = {
  header: css`
    width: 100%;
    height: ${headerHeight};
    background-color: ${colorPalette.main};
    z-index: ${zIndex.header};
    display: flex;
    align-items: center;
  `,
  wrapper: css`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  navigationItem: css`
    text-decoration: none;
    color: ${colorPalette.white};
    font-weight: ${fontWeight.bold};
    font-size: ${fontSize['18']};
    font-family: ${fontFamily.elido};
    margin-right: ${spacing['30']};
  `,
  fullNavigationWrapper: css`
    display: flex;
    align-items: center;
    ${Breakpoint.smallTablet} {
      display: none;
    }
  `,
};
const Header = () => {
  return (
    <header css={styles.header}>
      <Container>
        <div css={styles.wrapper}>
          <Link to={'/'} css={{ maxWidth: '100px' }}>
            <StaticImage src="../../images/logo.svg" alt="Ismegink.lt" placeholder="blurred" />
          </Link>

          <div css={styles.fullNavigationWrapper}></div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
